exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-dla-rodzica-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/dla-rodzica.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-dla-rodzica-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-faq-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/faq.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-faq-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-gra-konwentowa-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/gra-konwentowa.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-gra-konwentowa-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-kontakt-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/kontakt.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-kontakt-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-lista-atrakcji-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/lista-atrakcji.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-lista-atrakcji-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-moj-pierwszy-konwent-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/moj-pierwszy-konwent.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-moj-pierwszy-konwent-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-o-wydarzeniu-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/o-wydarzeniu.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-o-wydarzeniu-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-cosplay-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/regulamin-cosplay.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-cosplay-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-program-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/regulamin-program.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-program-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-uczestnika-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/regulamin-uczestnika.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-uczestnika-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-wystawcow-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/regulamin-wystawcow.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-regulamin-wystawcow-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-wolonariat-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/wolonariat.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-wolonariat-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-zgloszenia-cosplay-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/zgloszenia-cosplay.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-zgloszenia-cosplay-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-zgloszenia-program-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/zgloszenia-program.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-zgloszenia-program-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-zgloszenia-wystawcy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/zabenka/strony-konwentowe/tsukimi/src/markdown/zgloszenia-wystawcy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-zgloszenia-wystawcy-mdx" */)
}

